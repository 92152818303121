<template>
  <div
      :class="type === 'issue' ? 'container-default' : ''"
      class="view-container activity"
  >
    <div class="view">
      <div class="view-body">
        <div
            class="d-flex justify-content-between"
            v-if="type === 'project'"
        >
          <div class="d-flex">
            <div class="activity--filter-label">{{ $t('report.date_range') }}</div>
            <el-date-picker
                :picker-options="pickerOptions"
                format="yyyy-MM-dd"
                placeholder=""
                type="daterange"
                v-model="date_range"
            >
            </el-date-picker>
          </div>
          <el-button
              @click.prevent.stop="getActivity(true)"
              type="primary"
          >
            {{ $t('system.send') }}
          </el-button>
        </div>
        <div
            class="pb-3 activity--content"
            v-if="modelLength"
        >
          <!-- <h1 class="text-center"></h1> -->
          <ul class="activity--content-timeline pt-3">
            <template v-for='(days, index) in model'>
              <!-- timeline day -->
              <li
                  class="activity--content-timeLabel mb-3"
                  v-bind:key="index"
              >
                <span
                    :style="[{'background-color' : themeColor}]"
                    style="color: #fff"
                >{{ index }}</span>
              </li>
              <template v-for='(day, created_at) in days'>
                <li
                    class="activity--content-block"
                    v-bind:key="created_at"
                >
                  <template v-for="(item, iter) in day">
                    <!-- timeline icon -->
                    <!--<i v-if="item.prop_key !== 'notes' && iter === 0"-->
                    <!--v-bind:class="'fa fa-user bg-black'"></i>-->
                    <div v-bind:key="item.id">
                      <!--                      <i v-if="checkIfAvatarExist(item.userId) && iter === 0" class="fa">-->
                      <img
                          :alt="item.name"
                          :src="'data:image/jpeg;base64,' + getAvatar(item.userId)"
                          class="avatar--ifImage activity--content-avatar"
                          v-if="checkIfAvatarExist(item.userId) && iter === 0"
                      >
                      <!--                      </i>-->
                      <span
                          class="avatar--ifNotImage activity--content-avatar"
                          v-else-if="iter === 0"
                      >
                                {{ abbreviation(item.name) }}
                              </span>
                      <!--                            <span v-if="item.propKey === 'notes' && iter === 0" class="avatar&#45;&#45;ifNotImage activity&#45;&#45;content-avatar">-->
                      <!--                              <svg-icon icon-class="commenting-o"/>-->
                      <!--                            </span>-->
                      <div class="activity--content-timelineItem">
                        <div class="d-flex justify-content-between activity--content-timelineItem-header">
                          <!--<editor-fold desc="ISSUE-">-->
                          <h3
                              class="activity--content-timelineItem-title"
                              v-bind:id="'change-'+item.id"
                              v-if="iter == 0"
                          >
                            <!--ISSUE-->
                            {{ $t('system.user') }}
                            <!--<router-link v-if="item.usersDEL == 0" :to="'/user/' + item.user_id ">-->
                            <!--{{item.name ? item.name : ''}}-->
                            <!--</router-link>-->
                            <!--<span v-else>-->
                            <!--{{item.name ? item.name : ''}}-->
                            <!--</span>-->
                            <span class="text-info">
                                            {{ item.name ? item.name : '' }}
                                        </span>
                            {{ $t('json.subject') }}
                            <!--<router-link v-if="item.issuesDeletedAt === null"-->
                            <!--:to="'/project/' + item.issuesProjectID + '/issue/' + item.issuesID">-->
                            <!--{{item.issuesID ? item.issuesSubject : ''}}-->
                            <!--&lt;!&ndash;{{$t('activity.' + item.prop_key + '')}}&ndash;&gt;-->
                            <!--</router-link>-->
                            <!--<span v-else>#{{item.issuesID ? item.issuesID : ''}}</span>-->
                            <span class="text-info">#{{ item.classId ? item.classId : '' }}</span>
                          </h3>
                          <!--</editor-fold>-->
                          <span
                              class="activity--content-timelineItem-time"
                              v-if="iter === 0"
                          >
                                    <span class="time-icons">
                                        <i
                                            @click.prevent.stop="quoteNote(item)"
                                            title="Komentuj"
                                            v-if="item.propKey === 'notes' && ifHasPermission(['pm-add-notes'])"
                                        >
                                            <!--<svg-icon icon-class="commenting-o"/>-->
                                        </i>
                                        <template v-if="isOwnNote(item)">
                                            <i
                                                title="Edytuj"
                                                v-if="item.propKey === 'notes'"
                                                v-on:click="editNote(item)"
                                            >
                                                <!--<svg-icon icon-class="time"/>-->
                                            </i>
                                        </template>
                                        <template v-else>
                                            <i
                                                title="Edytuj"
                                                v-if="item.propKey === 'notes' && ifHasPermission(['pm-edit-notes'])"
                                                v-on:click="editNote(item)"
                                            >
                                                <!--<svg-icon icon-class="time"/>-->
                                            </i>
                                        </template>
                                    </span>
                                    {{ remFromTimestring(created_at, 'seconds') }}
                                    <!--<a v-bind:href="'#change-'+item.id" class="journal-link">#{{item.id}}</a>-->
                                </span>
                        </div>
                        <!--<editor-fold desc="DESCRIPTION-">-->
                        <div
                            class="activity--content-timelineBody"
                            v-if="item.propKey === 'description' "
                        >
                          <!--DESCRIPTION-->
                          <span>
                                        <b>{{ $t('json.' + item.type + '') }}</b>
                                    </span>
                          <span class="text-success">{{ $t('json.' + item.propKey) }}</span>
                          <span
                              @click="openModal(item.id)"
                              style="cursor:pointer"
                          >
                                        ({{ $t('system.diff') }})
                                    </span>
                        </div>
                        <!--</editor-fold>-->
                        <!--<editor-fold desc="NOTES-">-->
                        <div
                            class="activity--content-timelineBody"
                            v-else-if="item.propKey === 'notes'"
                        >
                          <!--NOTES-->
                          <span v-if="item.journalsType === 'INSERT'"><b>{{ $t('activity.added') }}</b></span>&nbsp;
                          <span v-else-if="item.journalsType === 'UPDATE'"><b>{{ $t('activity.updated') }}</b></span>&nbsp;
                          <span v-else-if="item.journalsType === 'REMOVE'"><b>{{ $t('activity.removed') }}</b></span>&nbsp;
                          <span class="text-success">{{ $t('json.' + item.propKey + '') }}</span>
                          <template v-if="!notes.edit[item.id]">
                            <span
                                class="text-info"
                                v-if="item.newValue"
                            >{{ item.newValue }}</span>
                            <template v-if="item.oldValue">{{ $t('activity.from') }}
                              <span class="text-info">{{ item.oldValue }}</span>
                            </template>
                          </template>
                          <div v-else>
                            <div class="timeline-notes">
                              <vue-editor
                                  :id="generateEditNoteId(item)"
                                  v-model="noteEditVal[item.id]"
                              ></vue-editor>
                              <div class="timeline-buttons">
                                <el-button
                                    type="primary"
                                    v-on:click="saveNote(item)"
                                >
                                  {{ $t('system.save') }}
                                </el-button>
                                <el-button
                                    @click.prevent.stop="cancelEditNote(item)"
                                    type="danger"
                                >
                                  {{ $t('system.cancel') }}
                                </el-button>
                              </div>
                            </div>
                          </div>

                          <div v-if="notes.quote[item.id]">
                            <div class="timeline-notes">
                              <vue-editor
                                  :id="generateEditNoteId(item)"
                                  v-model="noteQuoteVal[item.id]"
                              ></vue-editor>
                              <div class="timeline-buttons">
                                <button
                                    @click.prevent.stop="saveQuote(item)"
                                    class="btn btn-success btn-xs"
                                >
                                  {{ $t('system.save') }}
                                </button>
                                <button
                                    @click.prevent.stop="cancelQuoteNote(item)"
                                    class="btn btn-warning btn-xs"
                                >
                                  {{ $t('system.cancel') }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--</editor-fold>-->
                        <!--<editor-fold desc="USERS RELATION-">-->
                        <div
                            class="activity--content-timelineBody"
                            v-else-if="item.type !== 'INSERT' && (item.propKey === 'assigned_to' || item.propKey === 'attachments')"
                        >
                          <!--USERS RELATION-->
                          <span>
                                        <b>{{ $t('json.' + item.type + '') }}</b>
                                    </span>
                          <span class="text-success">
                                        {{ $t('json.' + item.propKey + '') }}
                                    </span>
                          <div v-if="item.type === 'Change'">
                            <b>{{ $t('system.from') }} </b>
                            <span v-if="item.oldValue">
                                            <span
                                                class="text-info"
                                                v-html="item.oldValue"
                                            > </span>
                                        </span>
                            <b> {{ $t('system.to') }} </b>
                            <span v-if="item.newValue">
                                            <span
                                                class="text-info"
                                                v-html="item.newValue"
                                            > </span>
                                        </span>
                          </div>
                          <div v-else-if="item.type === 'Remove'">
                            <span
                                class="text-info"
                                v-html="item.oldValue"
                                v-if="item.oldValue"
                            > </span>
                          </div>
                          <div v-else>
                            <span
                                class="text-info"
                                v-html="item.newValue"
                                v-if="item.newValue"
                            > </span>
                          </div>
                        </div>
                        <!--</editor-fold>-->
                        <!--<editor-fold desc="!INSERT-">-->
                        <div
                            class="activity--content-timelineBody"
                            v-else-if="item.type !== 'INSERT' && (item.propKey !== 'description' && item.propKey !== 'log_time')"
                        >
                          <!--!INSERT-->
                          <!-- ! STOP TIME BEGIN-->
                          <span v-if="item.propKey === 'stop_time'">
                                        <b>{{ $t('activity.stop_time') }}</b>&nbsp;
                                    </span>
                          <!-- ! STOP TIME END-->
                          <!-- ! START TIME BEGIN-->
                          <span v-else-if="item.propKey === 'start_time'">
                                        <b>{{ $t('activity.start_time') }}</b>&nbsp;
                                    </span>
                          <!-- ! START TIME END-->
                          <!-- ! PAUSE TIME BEGIN-->
                          <span v-else-if="item.propKey === 'pause_time'">
                                        <b>{{ $t('activity.pause_time') }}</b>&nbsp;
                                    </span>
                          <!-- ! PAUSE TIME END-->
                          <span
                              v-else-if="(item.oldValue != '' && item.newValue != '') && (item.oldValue !== null && item.newValue !== null)"
                          >
                                        <b v-if="item.type === 'Timetracker' && item.propKey !== 'log_time'">
                                            {{ $t('json.' + item.type + '') }}
                                            {{ $t('activity.and') }} {{ $t('activity.change') }}
                                        </b>
                                        <b v-else>{{ $t('json.' + item.type + '') }}</b>&nbsp;
                                        <span class="text-success">{{ $t('json.' + item.propKey + '') }}</span>&nbsp;
                                        <b>{{ $t('system.from') }}</b>&nbsp;
                                        <span
                                            class="text-info"
                                            v-html="item.oldValue"
                                            v-if="item.oldValue"
                                        > </span><b> {{ $t('system.to') }}</b>&nbsp;
                                        <span
                                            class="text-info"
                                            v-html="item.newValue"
                                            v-if="item.newValue"
                                        ></span></span>
                          <span v-else>
                                        <span class="text-success">{{ $t('json.' + item.propKey + '') }}</span>&nbsp;
                                        <b>{{ $t('system.from') }}</b>&nbsp;
                                        <span
                                            class="text-info"
                                            v-html="item.oldValue"
                                            v-if="item.oldValue"
                                        > </span>&nbsp;
                                        <b>{{ $t('system.to') }}</b>&nbsp;
                                        <span
                                            class="text-info"
                                            v-html="item.newValue"
                                            v-if="item.newValue"
                                        ></span>&nbsp;
                          </span>
                        </div>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="TIME ENTRY-">-->
                        <div
                            class="activity--content-timelineBody"
                            v-else-if="item.type === 'Timetracker' && item.propKey === 'log_time'"
                        >
                          <span class="text-success">{{ $t('json.' + item.propKey + '') }}</span>&nbsp;
                          <span
                              class="text-info"
                              v-html="item.newValue"
                              v-if="item.newValue"
                          ></span>&nbsp;
                        </div>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="NEW ISSUE">-->
                        <div
                            class="activity--content-timelineBody"
                            v-else-if="item.type === 'INSERT' && item.className == 'Issue'"
                        >
                          <span class="text-success">{{ $t('json.' + item.propKey + '') }}</span>&nbsp;
                          <b>{{ $t('activity.new_issue') }}</b> #{{ item.classId }}
                        </div>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="NEW PROJECT">-->
                        <div
                            class="activity--content-timelineBody"
                            v-else-if="item.type === 'INSERT' && item.className == 'Project'"
                        >
                          <span class="text-success">{{ $t('activity.' + item.propKey + '') }}</span>
                          <b>{{ $t('activity.new_project') }}</b>
                          <router-link
                              :to="'/project/' + item.projectsID"
                              v-if="item.projectsDeletedAt === null"
                          >
                            {{ item.projectsName ? item.projectsName : '' }}
                          </router-link>
                          <span v-else>{{ item.projectsName ? item.projectsName : '' }}</span>
                        </div>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="UPDATE PROJECT">-->
                        <div
                            class="activity--content-timelineBody"
                            v-else-if="item.className === 'Project' && item.type === 'UPDATE'"
                        >
                          <!--UPDATE-->
                          <b v-if="item.oldValue == 0 && item.newValue == 1">{{ $t('activity.project_opened') }} </b>
                          <b v-else>{{ $t('activity.project_closed') }}</b>
                          <router-link
                              :to="'/project/' + item.projectsID"
                              v-if="item.projectsDeletedAt === null"
                          >
                            {{ item.projectsName ? item.projectsName : '' }}
                          </router-link>
                          <span v-else>{{ item.projectsName ? item.projectsName : '' }}</span>
                        </div>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="ELSE">-->
                        <div
                            class="activity--content-timelineBody"
                            v-else
                        >
                          ELSE
                        </div>
                        <!--</editor-fold>-->
                        <div style="display:none;">
                          <!--<a v-bind:href="'#change-'+item.id" class="journal-link">#{{item.id}}</a>-->
                          <span>#{{ item.id }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </li>
              </template>
              <!-- timeline time label -->
            </template>
            <!-- END timeline item -->
          </ul>

          <!--<form-modal v-if="showModal" v-on:close="close()" :header="header" :body="body"/>-->
          <el-dialog
              :title="$t('system.diff')"
              :visible.sync="showModal"
              append-to-body
              class="modal--customStyle"
              width="60%"
          >
            <div
                class="modal-body"
                v-html="body"
            ></div>
          </el-dialog>
          <!--<p v-show="false">{{getRefresh}}</p>-->
          <!--<mugen-scroll-->
          <!--class="activity&#45;&#45;mugen-scroll"-->
          <!--:handler="getActivity"-->
          <!--:should-handle="!loading && !finish">-->
          <!--<div v-if="loading && !finish"></div>-->
          <!--</mugen-scroll>-->
          <el-button
              :loading="loading"
              @click.prevent.stop="getActivity(false)"
              v-show="!finish"
          >
            {{ $t('system.load_more') }}
          </el-button>
        </div>
        <div
            class="no-date-title"
            v-else
        >{{ $t('system.no_data') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import striptags from 'striptags';
import {VueEditor} from 'vue2-editor';
import notification from '../../notification/notify';
import {abbreviation} from '../../helpers/functions';

export default {
  name: 'Activity',
  components: {
    // MugenScroll,
    VueEditor,
  },
  props: ['type', 'id', 'scrollableContainer', 'issueIdModal', 'activityMaxShow', 'quantityActivityPerPage'],
  data() {
    return {
      modelLength: 0,
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: this.$t('system.today'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: this.$t('system.last_week'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          }, {
            text: this.$t('system.last_month'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          }, {
            text: this.$t('system.last_3_months'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      date_range: [null, new Date()],
      model: {},
      avatars: [],
      params: {
        per_page: this.activityMaxShow || 50,
        page: 1,
        modelId: this.issueIdModal ? this.issueIdModal : this.id,
        date_start: null,
        date_end: null,
      },
      paginate: {
        total: 0,
        to: 0,
      },
      loading: false,
      notes: {
        edit: {},
        quote: {},
      },
      noteEditVal: {},
      noteQuoteVal: {},
      header: null,
      body: null,
      quantityActivity: this.quantityActivityPerPage || '',
      showModal: false,
      finish: false,
    };
  },
  computed: {
    themeColor() {
      return this.$store.state.app.color;
    },
    getRefresh: function () {
      if (this.$store.getters.getRefreshActivity) {
        this.getActivity();
        this.$store.commit('SET_REFRESH_ACTIVITY', false);
      }
      return this.$store.getters.getRefreshActivity;
    },
  },
  created() {
    this.getActivity();
  },
  watch: {
    'update.refresh': function () {
      if (this._self['update'].refresh && (this._self['update'].field === 'fieldActivity') && this.type === 'issue') {
        this.getActivity(true);
        this.$store.dispatch('generator/update', {field: 'fieldActivity', refresh: false});
      }
    },
    'update.refreshActivity': function () {
      if (this._self['update'].refreshActivity) {
        this.getActivity(true);
        this.$store.commit('generator/UPDATE_ACTIVITY', false);
      }
    },
  },
  methods: {
    abbreviation(str) {
      return abbreviation(str);
    },
    checkIfAvatarExist(userId) {
      const avatar = _.find(this.avatars, ['userId', userId]);
      return !!(avatar && avatar.image);
    },
    getAvatar(userId) {
      return _.find(this.avatars, {userId: userId}).image;
    },
    openModal(id) {
      this.getDiff(id);
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
    getDiff(id) {
      this.body = null;
      axios.get(`journal_detail/${id}`)
          .then(response => {
            if (response.status !== 200) {
              this.error = response.statusText;
              return;
            }
            this.body = response.data.model;
          })
          .catch(error => {
            this.error = error.response.statusText;
          });
    },
    getActivity: async function (search = false) {
      let vm = this;
      // vm.model = {};
      vm.loading = true;
      if (search) {
        vm.params.page = 1;
        vm.params.date_start = null;
        vm.params.date_end = null;
        vm.model = {};
        vm.finish = false;
      }
      var p = vm.params;
      // if (quantity) {
      //   p.per_page = quantity
      // }
      p.submodule = vm.$props.type;
      if (vm.date_range !== null) {
        if (vm.date_range[0] !== null) {
          p.startDate = moment(this.date_range[0]).format('YYYY-MM-DD');
          p.endDate = moment(this.date_range[1]).format('YYYY-MM-DD');
        }
      }
      await axios.get(`history`, {
        params: {
          ...p,
        },
      }).then(({data}) => {
        if (typeof data !== 'undefined') {
          if (typeof data.data.min_date !== 'undefined') {
            vm.date_range = [new Date(data.data.min_date), new Date()];
          }
          let tmp = data.data;
          if (!tmp && vm.params.page > 1) {
            vm.finish = true;
          } else {
            if (tmp !== null) {
              Object.keys(tmp).forEach(function (key) {
                if (!_.find(vm.avatars, ['userId', tmp[key]['userId']])) {
                  vm.avatars.push({
                    userId: tmp[key]['userId'],
                    image: null,
                    uploaded: false,
                  });
                }
                // vm.avatars.push(tmp[key]['userId'])
                var d = tmp[key]['date'];
                var jct = tmp[key]['journalDetailsCreatedAt'];
                if (typeof vm.model[d] === 'undefined') {
                  vm.model[d] = {};
                }
                if (typeof vm.model[d][jct] === 'undefined') {
                  vm.model[d][jct] = [];
                }
                vm.model[d][jct].push(tmp[key]);
                if (tmp[key]['propKey'] === 'notes') {
                  vm.noteEditVal[tmp[key]['id']] = tmp[key]['newValue'];
                  vm.noteQuoteVal[tmp[key]['id']] = vm.quoteNoteValue(tmp[key]);
                }
              });
            }
            vm.params.page++;
            vm.loading = false;
            if (tmp.length < vm.params.per_page) {
              vm.finish = true;
            }
          }
          if (this.model) {
            this.modelLength = Object.keys(this.model).length;
          } else {
            this.modelLength = 0;
          }
        }
      }).then(() => {
        _.forEach(this.avatars, (avatar) => {
          if (!avatar.uploaded) {
            this.fetchAvatar(avatar.userId);
          }
        });
      });
    },
    fetchAvatar(userId) {
      axios.get(`avatar/${userId}`).then((response) => {
        const avatar = _.find(this.avatars, ['userId', userId]);
        avatar.image = response.data;
        avatar.uploaded = true;
      });
    },
    editNote(item) {
      this.$set(this.notes.edit, item.id, true);
    },
    cancelEditNote(item) {
      this.$set(this.notes.edit, item.id, false);
      this.getActivity();
    },
    saveNote(item) {
      let vm = this;
      axios.put(`note/${item.id}`, {
        note: vm.noteEditVal[item.id],
      })
          .then(function () {
            vm.cancelEditNote(item); //to close wyswig
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    quoteNote(item) {
      this.$set(this.notes.quote, item.id, true);
    },
    cancelQuoteNote(item) {
      this.$set(this.notes.quote, item.id, false);
    },
    saveQuote(item) {
      const that = this;
      let vm = this;
      axios.post(`note/quote`, {
        journalId: item.journal_id,
        note: this.noteQuoteVal[item.id],
      })
          .then(function () {
            that.cancelQuoteNote(item);
            that.getActivity();
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    quoteNoteValue(item) {
      let text = item.user_name + ' napisał(a):';
      text += '<blockquote><em>' + striptags(item.newValue) + '</em></blockquote>';
      text += '<p></p><p></p>';
      return text;
    },
    generateEditNoteId(item) {
      return 'editNote' + item.id;
    },
    isOwnNote() {
      return true;

      // let user = this.$store.state.user;
      // let hasAccess = this.$store.getters.checkPermissions(['pm-edit-own-notes'], true);
      // if (user.admin === 1 || (item.user_id == user.id && hasAccess)) {
      //   return true;
      // }
      // return false;
    },
    remFromTimestring($time, type) {
      if ($time) {
        if (type === 'hours') {
          return $time.substring(0, $time.length - 9);
        }
        if (type === 'seconds') {
          return $time.substring(0, $time.length - 3);
        }
      }
      return '';
    },
    prettyJSON: function (json) {
      if (json) {
        let tmp = JSON.stringify(json, undefined, 4);
        tmp = tmp.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
// eslint-disable-next-line/
        return tmp.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+]?\d+)?)/g, function (match) {
          var cls = 'number';
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = 'key';
            } else {
              cls = 'string';
            }
          } else if (/true|false/.test(match)) {
            cls = 'boolean';
          } else if (/null/.test(match)) {
            cls = 'null';
          }
          return '<span class="' + cls + '">' + match + '</span>';
        });
      }
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.activity--filter-label {
  font-weight: 600;
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.activity--content {
}

.activity--content-timeline {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;

  &:before {
    content: '';
    position: absolute;
    top: 20px;
    bottom: 0px;
    left: 31px;
    width: 4px;
    background: #909399;
    margin: 0;
    border-radius: 2px;
  }
}

.activity--content-timeLabel span {
  font-weight: 600;
  padding: 5px 10px;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  z-index: 1;
  position: relative;
}

.activity--content-avatar {
  position: absolute;
  background: #fff;
  left: 20px;
  top: 0;
}

.activity--content-block {
  position: relative;
  margin-bottom: 15px;

  &:before, &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }

  div:first-child {
    .activity--content-timelineItem {
      border-radius: 3px 3px 0 0;

      .activity--content-timelineItem-header {
        border-bottom: 1px solid #c1c1c1;
        padding: 0.25rem
      }
    }
  }

  div:last-child {
    .activity--content-timelineItem {
      border-radius: 0 0 3px 3px;
    }
  }
}

.activity--content-timelineItem {
  margin-top: 0;
  background: #fff;
  color: #444;
  margin-left: 60px;
  margin-right: 0px;
  padding: 0;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, .1);
  position: relative;
}

.activity--content-timelineItem-time {
  color: #999;
  font-size: 12px;
}

.activity--content-timelineItem-header {
}

.activity--content-timelineItem-title {
  font-size: 13px;
  margin: 0;
  font-weight: 700;

  a {
    font-weight: 600;
    color: #3c8dbc;
    text-decoration: none;
  }
}

.activity--content-timelineBody {
  padding: 0.25rem;
  font-size: 12px;
  color: #909399;

  .text-info {
    word-wrap: break-word;
  }
}

p {
  margin: 0 0 11px;
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.activity--mugen-scroll {
  .el-icon-loading {
    font-size: 26px;
  }
}
</style>
